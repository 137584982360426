<template>
  <div class="home">
    Welcome Cloud Home
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>
